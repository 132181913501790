import styled from 'styled-components'
import {
  space,
  color,
  layout,
  flexbox,
  position,
  typography,
  border,
  grid,
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  PositionProps,
  TypographyProps,
  BorderProps,
  GridProps,
} from 'styled-system'

export type Props = ColorProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  TypographyProps &
  BorderProps &
  GridProps

const Button = styled.button<Props>(
  {
    boxSizing: 'border-box',
    appearance: 'none',
    border: 0,
    backgroundColor: '#fdfdfd',
    borderRadius: 4,
    minHeight: '2.5rem',
    minWidth: '2.5rem',
    cursor: 'pointer',
  },
  space,
  color,
  layout,
  flexbox,
  position,
  typography,
  border,
  grid,
  `
    transition: all ease-in 100ms;

    &:hover {
      background-color: #c4c4c4;
    }
  `,
)

export default Button
