import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
  compose,
  space,
  layout,
  flexbox,
  border,
  position,
  color,
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexboxProps,
  BorderProps,
  PositionProps,
} from 'styled-system'

export type MotionBoxProps = SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  BorderProps &
  PositionProps

const MotionBox = styled(motion.div)<MotionBoxProps>(
  { boxSizing: 'border-box' },
  compose(space, color, layout, flexbox, border, position),
)

export default MotionBox
