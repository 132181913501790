import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import Img from 'gatsby-image'

import normalize from '../normalize'
import Box from '../components/box'
import { Modal } from '../components/modal'
import Button from '../components/button'

const GlobalStyle = createGlobalStyle`
  ${normalize}
  body {
    background: #304750;
    color: #f9cab8;
    font-family: Roboto;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin: 0;
  }
  a {
    color: #f9cab8;
    text-decoration: none;
    font-weight: 500;
  }
`

const GridImage = styled(({ className, fluid, alt, ...props }) => (
  <Box
    className={className}
    borderRadius="1.5rem"
    overflow="hidden"
    style={{ cursor: 'pointer' }}
    {...props}
  >
    <Img fluid={fluid} alt={alt} />
  </Box>
))`
  transition: all ease-in 100ms;

  &:hover {
    opacity: 0.75;
  }
`

const Home = ({
  data: {
    contentfulMediaGrid: { images },
  },
}) => {
  const [modal, setModal] = React.useState(null)

  return (
    <>
      <Helmet>
        <title>Portfolio | Sheryl Sketches</title>
        <meta
          name="description"
          content="Creating mediocre illustrations in a world of over exceeding expectations."
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="theme-color" content="#304750" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-P85M0X6047"
        />
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-P85M0X6047');
        `}</script>
      </Helmet>
      <GlobalStyle />
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection={['column', 'column', 'row', 'row']}
        my={3}
      >
        <Box as="h1" flexGrow={1} fontSize={[6, 7, 8]} px={4} my={3}>
          Sheryl
          <br />
          Sketches
        </Box>
        <Box
          display="flex"
          alignItems="baseline"
          flexDirection="row"
          px={4}
          my={3}
        >
          <Box
            as="a"
            borderRadius="1.5rem"
            bg="rgba(0,0,0,0.25)"
            href="mailto:sheryl.a.davie@gmail.com"
            alignItems="center"
            px={[3, 4]}
            py={2}
            style={{ whiteSpace: 'nowrap' }}
          >
            <FontAwesomeIcon size="lg" icon={faEnvelope} color="#f9cab8" />
            &nbsp;Email
          </Box>
          <Box
            as="a"
            borderRadius="1.5rem"
            bg="rgba(0,0,0,0.25)"
            href="https://www.instagram.com/sherylsketches/"
            alignItems="center"
            px={[3, 4]}
            py={2}
            ml={2}
            style={{ whiteSpace: 'nowrap' }}
          >
            <FontAwesomeIcon size="lg" icon={faInstagram} color="#f9cab8" />
            &nbsp;Instagram
          </Box>
        </Box>
      </Box>
      <Box
        as="h2"
        fontWeight="600"
        flexGrow={1}
        fontSize={[4, 5, 6]}
        px={4}
        mb={4}
      >
        Works
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr 1fr',
          '1fr 1fr 1fr 1fr',
        ]}
        px={4}
        gridGap={4}
        width="100%"
      >
        {(images ?? []).map(img => (
          <GridImage
            key={img.file?.fileName}
            fluid={img.fluid}
            alt={img.file?.fileName}
            onClick={() => setModal(img)}
          />
        ))}
      </Box>
      <Modal visible={modal !== null} onBackground={() => setModal(null)}>
        <Box
          position="relative"
          width="min(36rem, 95vw)"
          height="min(36rem, 95vh)"
          minWidth="12rem"
          minHeight="12rem"
          p={2}
        >
          <Box
            position="absolute"
            right={1}
            top={1}
            zIndex={1}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button onClick={() => setModal(null)}>
              <FontAwesomeIcon size="lg" icon={faTimesCircle} color="#304750" />
            </Button>
          </Box>
          {modal && (
            <Img
              key={modal.file?.fileName}
              fluid={modal.fluid}
              alt={modal.file?.fileName}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </Box>
      </Modal>
    </>
  )
}

export const query = graphql`
  query MyQuery {
    contentfulMediaGrid {
      images {
        file {
          fileName
        }
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default Home
