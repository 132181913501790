import React from 'react'

import MotionBox from './motion-box'
import Box from './box'

export const Modal = ({
  children,
  visible,
  onBackground,
  ...props
}) => (
  <>
    <MotionBox
      style={{ cursor: 'pointer' }}
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      backgroundColor="rgba(0,0,0,0.666)"
      zIndex={9998}
      onClick={onBackground ? onBackground : null}
      alignItems="center"
      justifyContent="center"
      initial="closed"
      animate={visible ? 'open' : 'closed'}
      variants={{
        open: { display: 'flex', opacity: 1, transition: { duration: 0.25 } },
        closed: {
          display: 'none',
          opacity: 0,
          transition: { duration: 0.25, when: 'afterChildren' },
        },
      }}
    >
      <MotionBox
        style={{ cursor: 'default' }}
        variants={{
          open: { scale: 1, transition: { duration: 0.25 } },
          closed: { scale: 0, transition: { duration: 0.25 } },
        }}
        onClick={event => event.stopPropagation()}
        {...props}
      >
        <Box
          display="flex"
          m={2}
          borderRadius={4}
          bg="#fdfdfd"
          flexDirection="column"
          alignItems="flex-start"
        >
          {children}
        </Box>
      </MotionBox>
    </MotionBox>
  </>
)
