import styled from 'styled-components'
import {
  space,
  color,
  layout,
  flexbox,
  position,
  typography,
  border,
  grid,
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  PositionProps,
  TypographyProps,
  BorderProps,
  GridProps,
} from 'styled-system'

export type Props = ColorProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  TypographyProps &
  BorderProps &
  GridProps

const Box = styled.div<Props>(
  { boxSizing: 'border-box' },
  space,
  color,
  layout,
  flexbox,
  position,
  typography,
  border,
  grid,
)

export default Box
